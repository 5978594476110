<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="rows">
      <div class="col-lg-4 col-md-6 mt-4 col-xs-12">
        <h2>Services</h2>
        <ul class="list-dev">
          <li>Frontend Development</li>
          <li>Backend Development</li>
          <li>Mobile App Development</li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 col-xs-12">
        <h2>Company</h2>
        <ul class="list-dev">
          <li><a class="text-white" href="#about">About us</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 mt-4 col-xs-12">
        <h2>Connect</h2>
      </div>
    </div>
    <!-- <div class="container">
      <h3>KnightOne</h3>
      <p>
        Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi
        fuga maxime saepe commodi placeat.
      </p>
      <div class="social-links">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
      <div class="copyright">
        &copy; Copyright <strong><span>Scale Date Verse Technologies</span></strong
        >. All Rights Reserved
      </div>
    </div> -->
    <div class="credits">
      Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
export default {
  name: "FooterSection",
};
</script>

<style scoped></style>
